import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T>(
  operation?: string,
  result?: T,
  re_init?: T,
) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  constructor() {} // private messageService: MessageService,

  createHandleError = (serviceName = "") => {
    return <T>(operation = "operation", result = {} as T, re_init = {} as T) =>
      this.handleError(serviceName, operation, result, re_init);
  };

  handleError<T>(
    serviceName = "",
    operation = "operation",
    result = {} as T,
    re_init = {} as T,
  ) {
    return (error: HttpErrorResponse): Observable<T> => {
      if (operation == "login_service") {
        if (error.status == 401) {
          return of(error.error);
        }
      } else {
        if (error.status == 400) {
          return of(error.error);
        }
        if (error.status == 404) {
          return of(error.error);
        }
        if (error.status == 500) {
          alert("500 Exception Ocurred " + error.error);
        }
      }

      return of(result);
    };
  }
}
